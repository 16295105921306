import {
  Button,
  DmailIcon,
  GalxeIcon,
  IntractIcon,
  ProofOfHumanityTaskCard,
  Skeleton,
  TalismanIcon,
  Tooltip,
} from 'components';
import {
  CheckGalaxyQuestCompletedQuery,
  CheckGalaxyXCryfiQuestCompletedQuery,
  DeveloperQuestSubType,
  useClaimGalaxeQuestPointsMutation,
  useClaimGalaxyXCryfiQuestPointsMutation,
  useVerifyProofOfHumanityMutation,
} from 'gql';
import { RewardType } from './useDeveloperQuests';
import { useCookies } from 'react-cookie';
import { useEVM } from './useEVM';
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { useApi } from 'context';
import { isProduction, pathTo } from 'utils';
import { HTMLAttributes, VoidFn } from 'types';
import { useNavigate } from 'react-router-dom';

export interface QuestButtonProps extends HTMLAttributes<HTMLButtonElement> {
  handleClick?: VoidFn;
  isLoading?: boolean;
}

export const QuestTaskButton = (props: QuestButtonProps) => {
  return (
    <Button
      variant="light"
      classNames={{
        base: 'h-[unset] px-3 !py-[9px] lg:!w-[172px] !w-full lg:!max-w-[172px]',
        container: 'md:text-sm text-xs md:leading-[22px] leading-[18px]',
      }}
      isLoading={props.isLoading}
      onClick={props.handleClick}
    >
      {props.children}
    </Button>
  );
};

export const useCommunityQuests = (
  galaxyQuestCompleted: CheckGalaxyQuestCompletedQuery | undefined,
  galaxyXCryfiQuestCompleted: CheckGalaxyXCryfiQuestCompletedQuery | undefined
) => {
  const [{ accessToken }] = useCookies(['accessToken']);
  const { evmWalletAddress, open } = useEVM();
  const { account, trackEvent, userPoints, fetchUserPoints, fetchUser } = useApi();
  const navigate = useNavigate();

  const [claimGalaxePoints, { data, loading: claimPointsLoading, error: claimPointsError }] =
    useClaimGalaxeQuestPointsMutation({
      fetchPolicy: 'no-cache',
    });

  const [
    claimGalaxeXCryfiPoints,
    { data: GalaxeXCryfiData, loading: claimCryfiPointsLoading, error: claimCryFiPointsError },
  ] = useClaimGalaxyXCryfiQuestPointsMutation({
    fetchPolicy: 'no-cache',
  });

  const [verifyProofOfHumanity, { loading: verifyProofOfHumanityLoading }] =
    useVerifyProofOfHumanityMutation({
      fetchPolicy: 'no-cache',
      onCompleted() {
        trackEvent('Proof of humanity Verified');
        fetchUserPoints();
        fetchUser();
      },
      onError() {
        window.open('https://www.intract.io/proof-of-humanity?pohReferralCode=ANALOG', '_blank');
      },
    });

  return [
    {
      tasks: [
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.ProofOfHumanity,
          title: "Prove you're a human with Proof of Humanity!",
          description:
            "Connect various accounts to collect Medallions to prove that you're human for extra rewards!",
          primaryPoints: '1 ATP',
          claimButton: true,
          taskButton: (
            <QuestTaskButton
              isLoading={verifyProofOfHumanityLoading}
              handleClick={() => {
                trackEvent('quest_clicks', {
                  communityQuest: 'Proof of Humanity',
                });
                if (evmWalletAddress) {
                  verifyProofOfHumanity({
                    variables: { evmWalletAddress: evmWalletAddress as string, accessToken },
                  });
                } else {
                  open();
                }
              }}
            >
              {!evmWalletAddress ? 'Connect Wallet' : 'Claim'}
            </QuestTaskButton>
          ),
          cardComponent: ProofOfHumanityTaskCard,
        },
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.SignInWithEmail,
          title: 'Sign in with email',
          description: 'You can sign in with Google or create a new account with any email.',
          primaryPoints: '1 ATP',
        },
      ],
    },
    {
      heading: 'Galxe',
      headingIcon: (
        <div className="h-8 w-8 bg-[#ffffff1a] rounded-md flex justify-center items-center">
          <GalxeIcon className="bg-[white]" />
        </div>
      ),
      tasks: [
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.GalaxeQuest,
          title: 'Participate and complete Analog’s Galxe Campaign',
          description:
            "After completing tasks on Galxe, be sure to connect your EVM wallet then click 'Claim' on the Galxe platform. Then, connect the same EVM wallet you used on Galxe to redeem your points here.",
          primaryPoints: '11 ATP',
          claimButton: true,
          maxTaskPoints: 11,
          ...(evmWalletAddress
            ? {
                additionalDescription: (
                  <>
                    <div className="flex gap-4 lg:flex-row flex-col p-4 pl-6">
                      <Skeleton.Loader className="h-10 mt-auto w-[150px]">
                        <div className="flex flex-col gap-2 ">
                          <div className="md:text-base text-sm">Claimed points on Galxe</div>
                          <div className="p-[10px] border border-solid border-[#393939] justify-between flex-row flex rounded-lg items-center shadow-[0px_0px_120.6px_0px_#ffffff14_inset] h-[40px]">
                            <div className="truncate w-full whitespace-pre-line line-clamp-1 break-all">
                              {galaxyQuestCompleted?.checkGalaxyQuestCompleted
                                ?.claimedPointsFromGalaxe || 0}{' '}
                              pts
                            </div>
                          </div>
                        </div>
                        <Button
                          variant="primary"
                          className="mt-auto"
                          isDisabled={
                            !evmWalletAddress ||
                            !galaxyQuestCompleted?.checkGalaxyQuestCompleted?.canClaimMore
                          }
                          isLoading={claimPointsLoading}
                          onClick={() => {
                            if (evmWalletAddress && accessToken) {
                              claimGalaxePoints({
                                variables: { evmWalletAddress: evmWalletAddress, accessToken },
                                refetchQueries: ['UserPoints', 'CheckGalaxyQuestCompleted'],
                              });
                              trackEvent('quest_clicks', {
                                communityQuest: 'Claim Galaxe Points',
                              });
                            }
                          }}
                        >
                          Claim
                        </Button>
                      </Skeleton.Loader>
                    </div>
                    {claimPointsError && (
                      <div className="flex [flex-basis:100%] items-center gap-1 text-rose-500 px-3 pb-3">
                        <InformationCircleIcon className="w-5 h-5" />
                        <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight whitespace-nowrap">
                          {claimPointsError.message}
                        </p>
                      </div>
                    )}
                    {data?.claimGalaxeQuestPoints && (
                      <div className="flex [flex-basis:100%] items-center gap-1 text-green-500 px-3 pb-3">
                        <CheckCircleIcon className="w-5 h-5" />
                        <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight whitespace-nowrap">
                          Claimed {data?.claimGalaxeQuestPoints} points
                        </p>
                      </div>
                    )}
                  </>
                ),
                taskButton: (
                  <QuestTaskButton
                    handleClick={() =>
                      window.open('https://app.galxe.com/quest/analog/GCv1ztzSdd', '_blank')
                    }
                  >
                    Get Started
                  </QuestTaskButton>
                ),
              }
            : {
                taskButton: (
                  <QuestTaskButton
                    handleClick={() => {
                      open();
                      trackEvent('quest_clicks', {
                        communityQuest: 'EVM Wallet Connect From Galxy Quest',
                      });
                    }}
                  >
                    Connect Wallet
                  </QuestTaskButton>
                ),
              }),
        },
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.GalaxeXCryfiQuest,
          title: 'Analog X Cryfi: $CRFY Initiative Program',
          description:
            'The more tasks you complete, the higher your chances of winning $1000 worth of $CRFY tokens and ATP. Be sure to connect the same EVM wallet you used on Galxe to redeem your points here.',
          primaryPoints: '8 ATP',
          claimButton: true,
          maxTaskPoints: 8,
          ...(evmWalletAddress
            ? {
                additionalDescription: (
                  <>
                    <div className="flex gap-4 lg:flex-row flex-col p-4 pl-6">
                      <Skeleton.Loader className="h-10 mt-auto w-[150px]">
                        <div className="flex flex-col gap-2 ">
                          <div className="md:text-base text-sm">Claimed points on Galxe</div>
                          <div className="p-[10px] border border-solid border-[#393939] justify-between flex-row flex rounded-lg items-center shadow-[0px_0px_120.6px_0px_#ffffff14_inset] h-[40px]">
                            <div className="truncate w-full whitespace-pre-line line-clamp-1 break-all">
                              {galaxyXCryfiQuestCompleted?.checkGalaxyXCryfiQuestCompleted
                                ?.claimedPointsFromGalaxe || 0}{' '}
                              pts
                            </div>
                          </div>
                        </div>
                        <Button
                          variant="primary"
                          className="mt-auto"
                          isDisabled={
                            !evmWalletAddress ||
                            !galaxyXCryfiQuestCompleted?.checkGalaxyXCryfiQuestCompleted
                              ?.canClaimMore
                          }
                          isLoading={claimCryfiPointsLoading}
                          onClick={() => {
                            if (evmWalletAddress && accessToken) {
                              claimGalaxeXCryfiPoints({
                                variables: { evmWalletAddress: evmWalletAddress, accessToken },
                                refetchQueries: ['UserPoints', 'CheckGalaxyXCryfiQuestCompleted'],
                              });
                              trackEvent('quest_clicks', {
                                communityQuest: 'Claim Galaxe X Cryfi Points',
                              });
                            }
                          }}
                        >
                          Claim
                        </Button>
                      </Skeleton.Loader>
                    </div>
                    {claimCryFiPointsError && (
                      <div className="flex [flex-basis:100%] items-center gap-1 text-rose-500 px-3 pb-3">
                        <InformationCircleIcon className="w-5 h-5" />
                        <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight whitespace-nowrap">
                          {claimCryFiPointsError.message}
                        </p>
                      </div>
                    )}
                    {GalaxeXCryfiData?.claimGalaxyXCryfiQuestPoints && (
                      <div className="flex [flex-basis:100%] items-center gap-1 text-green-500 px-3 pb-3">
                        <CheckCircleIcon className="w-5 h-5" />
                        <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight whitespace-nowrap">
                          Claimed {GalaxeXCryfiData?.claimGalaxyXCryfiQuestPoints} points
                        </p>
                      </div>
                    )}
                  </>
                ),
                taskButton: (
                  <QuestTaskButton
                    handleClick={() =>
                      window.open('https://app.galxe.com/quest/analog/GCQCKtxAoj', '_blank')
                    }
                  >
                    Get Started
                  </QuestTaskButton>
                ),
              }
            : {
                taskButton: (
                  <QuestTaskButton
                    handleClick={() => {
                      open();
                      trackEvent('quest_clicks', {
                        communityQuest: 'EVM Wallet Connect From Galxy Quest',
                      });
                    }}
                  >
                    Connect Wallet
                  </QuestTaskButton>
                ),
              }),
        },
      ],
    },
    /* Removed intract quest */
    {
      heading: 'Intract',
      headingIcon: <IntractIcon />,
      widePoints: true,
      tasks: [
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.Intract,
          title: 'Embark on the Analog Incentivized Odyssey!',
          description:
            'Quest is ended! Connect the same EVM wallet you used on Intract to redeem your points here.',
          primaryPoints: '11 ATP',
          secondaryPoints: '130 XP',
          secondaryPointsLabel: 'Intract Points',
          taskButton: (
            <QuestTaskButton
              handleClick={() => {
                window.open(
                  'https://www.intract.io/quest/6661a09dfcf066cdbc5bec04?utm_source=dashboard',
                  '_blank'
                );
              }}
            >
              Ended
            </QuestTaskButton>
          ),
        },
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.IntractXMetaStreet,
          title: 'Analog X Metastreet: The Takeover Saga',
          description:
            'Quest is ended! Connect the same EVM wallet you used on Intract to redeem your points here.',
          primaryPoints: '11 ATP',
          secondaryPoints: '90 XP',
          secondaryPointsLabel: 'Intract Points',
          taskButton: (
            <QuestTaskButton
              handleClick={() => {
                window.open(
                  'https://www.intract.io/quest/66b3bf5bddcc9d45b304eb39?utm_source=dashboard',
                  '_blank'
                );
              }}
            >
              Ended
            </QuestTaskButton>
          ),
        },
      ],
    },
    {
      heading: 'Dmail',
      widePoints: true,
      headingIcon: <DmailIcon />,
      tasks: [
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.DMail,
          title: 'Sign up with Dmail to claim your cross-chain decentralized email address',
          description: 'Earn points both on Dmail and Analog!',
          primaryPoints: '10 ATP',
          secondaryPoints: '80 DP',
          secondaryPointsLabel: 'Dmail Rewards',
          taskButton: (
            <QuestTaskButton
              handleClick={() => {
                window.open(
                  `${
                    isProduction ? 'https://mail.dmail.ai' : 'https://testmailhu9fg9h.dmail.ai'
                  }/login?analogusercode=${account?.referralCode}`,
                  '_blank'
                );
                trackEvent('quest_clicks', {
                  communityQuest: 'Dmail Quest Claim',
                });
              }}
            >
              Claim
            </QuestTaskButton>
          ),
        },
      ],
    },
    {
      heading: 'Talisman',
      headingIcon: <TalismanIcon />,
      tasks: [
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.Talisman,
          title: 'Earn extra points with Talisman quests!',
          description:
            'Use your Talisman wallet to complete quests and earn extra points on Talisman’s quest platform.',
          primaryPoints: '70 XP',
          primaryPointsLabel: 'Talisman Rewards',
          taskButton: (
            <QuestTaskButton
              handleClick={() => {
                window.open('https://quest.talisman.xyz/', '_blank');
                trackEvent('quest_clicks', {
                  communityQuest: 'Talisman Quest Claim',
                });
              }}
            >
              Earn
            </QuestTaskButton>
          ),
        },
      ],
    },

    {
      heading: 'Watch Game',
      tasks: [
        {
          rewardType: RewardType.MultipleTime,
          title: 'Participate in a Watch Game Voting Session',
          description: 'You get rewarded for participating.',
          primaryPoints: '10 ATP',
          pointsRewarded: userPoints?.UserPoints?.votingSessionParticipated,
          taskButton: (
            <QuestTaskButton
              handleClick={() => {
                navigate(pathTo('Game'));
                trackEvent('quest_clicks', {
                  communityQuest: 'Participate in a Watch Game Voting Session Get Started',
                });
              }}
            >
              Get Started
            </QuestTaskButton>
          ),
        },
        {
          rewardType: RewardType.MultipleTime,
          title: (
            <div className="flex flex-row gap-1 mb-1 items-center">
              <div>Your vote wins a Voting Session!</div>
              <div
                className="cursor-default text-xs max-h-[21px] min-w-fit flex items-center px-2 py-1 bg-[linear-gradient(140deg,#D274F74D_16.37%,#9A74F74D_50.02%)] rounded-full relative"
                data-tooltip-id="vote-wins-twice-tooltip"
              >
                <Tooltip
                  id="vote-wins-twice-tooltip"
                  content="If you win twice in a row!"
                  classNames="max-w-[80vw] break-words !bg-[#232224] !border-none"
                  classNameArrow="!border-none"
                  rest={{ arrowColor: '#232224' }}
                />
                X2 PTS
              </div>
            </div>
          ),
          description: 'You get rewarded if the View you voted on wins a Voting Session.',
          primaryPoints: '20 ATP',
          pointsRewarded: userPoints?.UserPoints?.winnerAsVoter,
        },
      ],
    },
  ];
};
