import { useEffect, useMemo, useState } from 'react';
import { Button, ExclamationMark, SmartContractIcon, Tooltip } from 'components';
import { ConnectedChainType, DeveloperQuestSubType } from 'gql';
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { RewardType } from './useDeveloperQuests';
import { useGMPQuestSupport } from './useGmpQuestSupport';
import { useEVM } from './useEVM';
import { useQuestPageData } from 'pages/Quests/useQuestPageData';

export function useGMPQuests() {
  const [scAddressEth, setScAddressEth] = useState({
    value: '',
    isTouched: false,
    isError: false,
  });
  const [scAddressAstar, setScAddressAstar] = useState({
    value: '',
    isTouched: false,
    isError: false,
  });

  const [transactionHashEth, setTransactionHashEth] = useState({
    value: '',
    isTouched: false,
    isError: false,
  });
  const [transactionHashAstar, setTransactionHashAstar] = useState({
    value: '',
    isTouched: false,
    isError: false,
  });
  const [error, setError] = useState<{
    deploySmartContractEth: string | null;
    deploySmartContractAstar: string | null;
    sendMessageEth: string | null;
    sendMessageAstar: string | null;
  }>({
    deploySmartContractEth: null,
    deploySmartContractAstar: null,
    sendMessageEth: null,
    sendMessageAstar: null,
  });
  const [success, setSuccess] = useState<{
    deploySmartContractEth: string | null;
    deploySmartContractAstar: string | null;
    sendMessageEth: string | null;
    sendMessageAstar: string | null;
  }>({
    deploySmartContractEth: null,
    deploySmartContractAstar: null,
    sendMessageEth: null,
    sendMessageAstar: null,
  });
  const { completedQuests, completedDevQuestsCount } = useQuestPageData();
  const { handleGmpSubmitMessage, gmpSubmitMessageLoadingSepolia, gmpSubmitMessageLoadingShibuya } =
    useGMPQuestSupport(
      completedQuests?.some((point) => point?.type === 'connectEVMWalletOnTestnet')
    );
  const { open, isEvmWalletConnected } = useEVM();

  const {
    isSentMessageUsingGmpGatewayOnSepoliaCompleted,
    isSentMessageUsingGmpGatewayOnShibuyaCompleted,
  } = useMemo(() => {
    const isSentMessageUsingGmpGatewayOnSepoliaCompleted =
      completedDevQuestsCount?.completedDevQuestCount?.some(
        (completedDevQuest) =>
          completedDevQuest?.type === DeveloperQuestSubType.SentMessageUsingGmpGatewayOnSepolia
      );
    const isSentMessageUsingGmpGatewayOnShibuyaCompleted =
      completedDevQuestsCount?.completedDevQuestCount?.some(
        (completedDevQuest) =>
          completedDevQuest?.type === DeveloperQuestSubType.SentMessageUsingGmpGatewayOnShibuya
      );
    console.log(
      isSentMessageUsingGmpGatewayOnSepoliaCompleted,
      isSentMessageUsingGmpGatewayOnShibuyaCompleted
    );
    return {
      isSentMessageUsingGmpGatewayOnSepoliaCompleted,
      isSentMessageUsingGmpGatewayOnShibuyaCompleted,
    };
  }, [completedDevQuestsCount?.completedDevQuestCount]);

  useEffect(() => {
    setError({ ...error, deploySmartContractAstar: null, deploySmartContractEth: null });
    setSuccess({ ...success, deploySmartContractAstar: null, deploySmartContractEth: null });
  }, [scAddressEth, scAddressAstar]);

  useEffect(() => {
    setError({ ...error, sendMessageAstar: null, sendMessageEth: null });
    setSuccess({ ...success, sendMessageAstar: null, sendMessageEth: null });
  }, [transactionHashEth, transactionHashAstar]);

  const onGmpSubmitMessage = (transactionChain: ConnectedChainType) => {
    const transactionHash =
      transactionChain === ConnectedChainType.Sepolia ? transactionHashEth : transactionHashAstar;
    const setTransactionHash =
      transactionChain === ConnectedChainType.Sepolia
        ? setTransactionHashEth
        : setTransactionHashAstar;
    if (!isEvmWalletConnected) {
      open();
    } else if (!/^[0-9a-f]{64}$/i.test(transactionHash.value.replace('0x', ''))) {
      setTransactionHash((prev) => ({
        ...prev,
        isError: true,
      }));
    } else {
      handleGmpSubmitMessage({
        connectedChain: transactionChain,
        transactionHash: transactionHash.value,
        onError(sendError) {
          setError({
            ...error,
            [transactionChain === ConnectedChainType.Sepolia
              ? 'sendMessageEth'
              : 'sendMessageAstar']: sendError,
          });
        },
        onSuccess() {
          setSuccess({
            ...success,
            [transactionChain === ConnectedChainType.Sepolia
              ? 'sendMessageEth'
              : 'sendMessageAstar']: 'Transaction verified successfully',
          });
        },
      });
    }
  };

  return {
    heading: 'GMP',
    blockText:
      'To claim your ATP Points for completing GMP quests, please connect your EVM account that will be used!',
    tasks: [
      {
        icon: SmartContractIcon,
        rewardType: RewardType.FirstTime,
        type: DeveloperQuestSubType.ConnectEvmWalletOnTestnet,
        title: 'Connect your EVM account for GMP Quest',
        description: 'Please connect your EVM wallet used for GMP quests to claim your points.',
        primaryPoints: '1 ATP',
      },
      {
        completed:
          isSentMessageUsingGmpGatewayOnShibuyaCompleted &&
          isSentMessageUsingGmpGatewayOnShibuyaCompleted,
        icon: SmartContractIcon,
        rewardType: RewardType.FirstTime,
        // type: DeveloperQuestSubType.SentMessageUsingGmpGatewayOnSepolia,
        title: 'Send 2x messages using GMP gateway contracts',
        description:
          'Send a GMP message from Sepolia to Shibuya and another one from Shibuya to Sepolia. Connect the EVM wallet you used to verify the GMP message was executed, if needed, you can change your EVM connected wallet by navigating to the top right of the page. Check our guides for help if needed.',
        primaryPoints: '20 ATP',
        additionalDescription: (
          <>
            <div className="flex gap-4 lg:flex-row flex-col p-4 pb-6 border-solid border-b border-[#202020]">
              <div className="flex-1  flex flex-col gap-2 ">
                <div>Transaction Hash</div>
                <div className="p-[10px] border border-solid border-[#393939] w-full justify-between flex-row flex rounded-lg items-center shadow-[0px_0px_120.6px_0px_#ffffff14_inset] h-[40px]">
                  <input
                    disabled={isSentMessageUsingGmpGatewayOnSepoliaCompleted}
                    value={transactionHashEth.value}
                    onChange={(e) =>
                      setTransactionHashEth({
                        value: e.target.value,
                        isTouched: true,
                        isError: false,
                      })
                    }
                    className="truncate w-full whitespace-pre-line line-clamp-1 break-all"
                  />
                  {((transactionHashEth.isError && transactionHashEth.isTouched) ||
                    /^.{40}$/.test(transactionHashEth.value)) && (
                    <div data-tooltip-id={'transaction-hash'} className="relative">
                      <ExclamationMark />
                      <Tooltip
                        delay={1000}
                        id="transaction-hash"
                        content="Not a valid transaction hash"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex-1   flex flex-col gap-2 ">
                <div>Sender Chain</div>
                <div className="flex rounded-lg items-center shadow-[0px_0px_120.6px_0px_#ffffff14_inset] h-[40px] ps-3">
                  Ethereum - sepolia
                </div>
              </div>
              <Button
                variant="primary"
                className="mt-auto"
                isDisabled={
                  !transactionHashEth.value ||
                  (transactionHashEth.isError && transactionHashEth.isTouched) ||
                  isSentMessageUsingGmpGatewayOnSepoliaCompleted
                }
                onClick={() => onGmpSubmitMessage(ConnectedChainType.Sepolia)}
                isLoading={gmpSubmitMessageLoadingSepolia}
              >
                {isSentMessageUsingGmpGatewayOnSepoliaCompleted ? 'Verified' : 'Verify'}
              </Button>
            </div>
            <div className="flex gap-4 lg:flex-row flex-col p-4">
              <div className="flex-1  flex flex-col gap-2 ">
                <div>Transaction Hash</div>
                <div className="p-[10px] border border-solid border-[#393939] w-full justify-between flex-row flex rounded-lg items-center shadow-[0px_0px_120.6px_0px_#ffffff14_inset] h-[40px]">
                  <input
                    disabled={isSentMessageUsingGmpGatewayOnShibuyaCompleted}
                    value={transactionHashAstar.value}
                    onChange={(e) =>
                      setTransactionHashAstar({
                        value: e.target.value,
                        isTouched: true,
                        isError: false,
                      })
                    }
                    className="truncate w-full whitespace-pre-line line-clamp-1 break-all"
                  />
                  {((transactionHashAstar.isError && transactionHashAstar.isTouched) ||
                    /^.{40}$/.test(transactionHashAstar.value)) && (
                    <div data-tooltip-id={'transaction-hash'} className="relative">
                      <ExclamationMark />
                      <Tooltip
                        delay={1000}
                        id="transaction-hash"
                        content="Not a valid transaction hash"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex-1   flex flex-col gap-2 ">
                <div>Sender Chain</div>
                <div className="flex rounded-lg items-center shadow-[0px_0px_120.6px_0px_#ffffff14_inset] h-[40px] ps-3">
                  Astar - shibuya
                </div>
              </div>
              <Button
                variant="primary"
                className="mt-auto"
                isDisabled={
                  !transactionHashAstar.value ||
                  (transactionHashAstar.isError && transactionHashAstar.isTouched) ||
                  isSentMessageUsingGmpGatewayOnShibuyaCompleted
                }
                onClick={() => onGmpSubmitMessage(ConnectedChainType.Shibuya)}
                isLoading={gmpSubmitMessageLoadingShibuya}
              >
                {isSentMessageUsingGmpGatewayOnShibuyaCompleted ? 'Verified' : 'Verify'}
              </Button>
            </div>
            {(error.sendMessageAstar || error.sendMessageEth) && (
              <div className="flex [flex-basis:100%] items-center gap-1 text-rose-500 px-3 pb-3">
                <InformationCircleIcon className="w-5 h-5" />
                <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight whitespace-nowrap">
                  {error.sendMessageAstar || error.sendMessageEth}
                </p>
              </div>
            )}
            {(success.sendMessageAstar || success.sendMessageEth) && (
              <div className="flex [flex-basis:100%] items-center gap-1 text-green-500 px-3 pb-3">
                <CheckCircleIcon className="w-5 h-5" />
                <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight whitespace-nowrap">
                  {success.sendMessageAstar || success.sendMessageEth}
                </p>
              </div>
            )}
          </>
        ),
      },
    ],
  };
}
