/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, ReactNode, useContext, useEffect } from 'react';
import { useApi } from 'context';
import {
  CheckGalaxyQuestCompletedQuery,
  CheckGalaxyXCryfiQuestCompletedQuery,
  CompletedDevQuestCountQuery,
  DeveloperQuestSubType,
  useCheckGalaxyQuestCompletedQuery,
  useCheckGalaxyXCryfiQuestCompletedQuery,
  useClaimIntractPointMutation,
  useCompletedDevQuestCountLazyQuery,
  UserPointsQuery,
} from 'gql';
import { useEVM } from 'hooks/quests';
import { useCookies } from 'react-cookie';

interface QuestContextData {
  fetchCompletedDevQuestCount: () => void;
  refetchDevQuestCount: () => void;
  completedDevQuestsCount?: CompletedDevQuestCountQuery;
  userPoints?: UserPointsQuery;
  completedQuests: Array<{
    __typename?: 'QuestPointsSubType';
    points?: number | null;
    type?: DeveloperQuestSubType | null;
  } | null>;
  galaxyQuestCompleted?: CheckGalaxyQuestCompletedQuery;
  galaxyXCryfiQuestCompleted?: CheckGalaxyXCryfiQuestCompletedQuery;
  isUserPointsLoading?: boolean;
  loadingQuestData?: boolean;
}

const QuestContext = createContext<QuestContextData>({
  completedQuests: [],
  fetchCompletedDevQuestCount: () => {},
  refetchDevQuestCount: () => {},
});

export const QuestPageDataProvider = ({ children }: { children: ReactNode }) => {
  const [{ accessToken }] = useCookies(['accessToken']);
  const { evmWalletAddress } = useEVM();
  const { userPoints, isUserPointsLoading, fetchUserPoints } = useApi();

  const [
    fetchCompletedDevQuestCount,
    {
      data: completedDevQuestsCount,
      loading: completedCountLoading,
      refetch: refetchDevQuestCount,
    },
  ] = useCompletedDevQuestCountLazyQuery({
    variables: { accessToken },
  });

  const [claimIntractPoint] = useClaimIntractPointMutation();

  useEffect(() => {
    if (
      userPoints &&
      !userPoints?.UserPoints?.questPointsSubType?.find(
        (data) => data?.type === DeveloperQuestSubType.IntractXMetaStreet
      )
    ) {
      claimIntractPoint({
        variables: { evmAddress: evmWalletAddress as string, accessToken },
        onCompleted(data) {
          if (data.claimIntractPoint?.awarded) {
            fetchUserPoints();
          }
        },
      });
    }
  }, [accessToken, userPoints, evmWalletAddress, userPoints?.UserPoints?.questPointsSubType]);

  const { data: galaxyQuestCompleted, loading: checkGalaxyQuestLoading } =
    useCheckGalaxyQuestCompletedQuery({
      variables: {
        evmWalletAddress: evmWalletAddress as string,
        accessToken,
      },
      skip: !evmWalletAddress || !accessToken,
    });

  const { data: galaxyXCryfiQuestCompleted, loading: checkGalaxyXCryfiQuestLoading } =
    useCheckGalaxyXCryfiQuestCompletedQuery({
      variables: {
        evmWalletAddress: evmWalletAddress as string,
        accessToken,
      },
      skip: !evmWalletAddress || !accessToken,
    });

  return (
    <QuestContext.Provider
      value={{
        fetchCompletedDevQuestCount,
        refetchDevQuestCount,
        completedDevQuestsCount,
        userPoints,
        completedQuests: userPoints?.UserPoints?.questPointsSubType || [],
        galaxyQuestCompleted,
        galaxyXCryfiQuestCompleted,
        isUserPointsLoading,
        loadingQuestData:
          completedCountLoading ||
          isUserPointsLoading ||
          checkGalaxyQuestLoading ||
          checkGalaxyXCryfiQuestLoading,
      }}
    >
      {children}
    </QuestContext.Provider>
  );
};

export const useQuestPageData = () => useContext(QuestContext);
