import { useEffect, useMemo } from 'react';
import {
  BillIcon,
  ProofOfHumanityTaskCard,
  RefreshIcon,
  SigmaIcon,
  Skeleton,
  Star2Icon,
} from 'components';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { useCookies } from 'react-cookie';
import Banner from './Banner';
import { Referral } from './Referral';
import { useApi, useSessions } from 'context';
import { HomeOverview } from './HomeOverview';
import { useSetFpMutation, useVerifyProofOfHumanityMutation } from 'gql';
import { fpPromise } from 'main';
import { SocialConnectBanner } from 'components/SocialConnectBanner';
import { isProduction } from 'utils';
import { QuestTaskButton, useEVM } from 'hooks/quests';

export function DashBoard() {
  const [{ accessToken }] = useCookies(['accessToken']);
  const { userPoints: points, isUserPointsLoading, account, fetchUserPoints, fetchUser } = useApi();
  const [setFP] = useSetFpMutation();
  const { evmWalletAddress, open } = useEVM();

  const { isActiveSessionLoading } = useSessions();
  const [searchParams] = useSearchParams();

  const [_, setReferralCode] = useLocalStorage('referralCode', '');
  const [, setLandingSource] = useLocalStorage('landingSource', '');
  const [, setDmailusercode] = useLocalStorage('Dmailusercode', '');
  const [isFP, SetIsFP] = useLocalStorage('isFP', '');

  const userPoints = useMemo(() => {
    return {
      total: points?.UserPoints?.total || 0,
      quest:
        (points?.UserPoints?.developerQuest || 0) +
        (points?.UserPoints?.communityQuest || 0) +
        (points?.UserPoints?.partnerQuest || 0),
      referral: (points?.UserPoints?.referral || 0) + (points?.UserPoints?.expectedReferral || 0),
      game:
        (points?.UserPoints?.viewShortListed || 0) +
        (points?.UserPoints?.votingSessionParticipated || 0) +
        (points?.UserPoints?.winnerAsDesigner || 0) +
        (points?.UserPoints?.winnerAsVoter || 0),
    };
  }, [points]);

  useEffect(() => {
    const referrer = document.referrer;
    if (!accessToken && referrer) {
      setLandingSource(new URL(referrer).origin);
    }
  }, []);

  const handleSetFP = async () => {
    if (!isFP) {
      const FPResult = isProduction
        ? await (await fpPromise).get()
        : { visitorId: Math.floor(Math.random() * 10000000).toString() };
      await setFP({
        variables: {
          vId: FPResult.visitorId,
          accessToken,
        },
      });
      SetIsFP('true');
    }
  };

  useEffect(() => {
    const currentTime = new Date().getTime();
    if (searchParams.get('referral')) {
      setReferralCode(`${searchParams.get('referral')}-${currentTime}`);
    }
    if (searchParams.get('Dmailusercode')) {
      setDmailusercode(`${searchParams.get('Dmailusercode')}-${currentTime}`);
    }
    if (searchParams.get('sign-in-success')) {
      handleSetFP();
    }
  }, [
    searchParams.get('referral'),
    searchParams.get('Dmailusercode'),
    searchParams.get('sign-in-success'),
  ]);

  const [verifyProofOfHumanity, { loading: verifyProofOfHumanityLoading }] =
    useVerifyProofOfHumanityMutation({
      fetchPolicy: 'no-cache',
      onCompleted() {
        fetchUserPoints();
        fetchUser();
      },
      onError() {
        window.open('https://www.intract.io/proof-of-humanity?pohReferralCode=ANALOG', '_blank');
      },
    });

  return (
    <Skeleton.Provider isLoading={isUserPointsLoading || isActiveSessionLoading}>
      <div className="flex flex-col lg:gap-[60px] gap-[34px] md:my-[60px] my-[36px]">
        <SocialConnectBanner className="md:-mb-5" />
        {!account?.isVerifiedByPOH && (
          <ProofOfHumanityTaskCard
            isCompleted={false}
            task={{
              title: "Prove you're a human with Proof of Humanity!",
              description:
                "Connect various accounts to collect Medallions to prove that you're human for extra rewards!",
              taskButton: (
                <QuestTaskButton
                  isLoading={verifyProofOfHumanityLoading}
                  handleClick={() => {
                    if (evmWalletAddress) {
                      verifyProofOfHumanity({
                        variables: { evmWalletAddress: evmWalletAddress as string, accessToken },
                      });
                    } else {
                      open();
                    }
                  }}
                >
                  {!evmWalletAddress ? 'Connect Wallet' : 'Claim'}
                </QuestTaskButton>
              ),
            }}
          />
        )}
        <HomeOverview
          sections={[
            {
              icon: <SigmaIcon className="lg:h-[30px] lg:w-[30px] h-7 w-7" />,
              label: 'Total ATP Points',
              data: userPoints.total,
            },
            {
              icon: <BillIcon className="h-6 w-6" />,
              label: 'ATP from Quests',
              data: userPoints.quest,
            },
            {
              icon: <RefreshIcon className="h-6 w-6" />,
              label: 'ATP from Referrals',
              data: userPoints.referral,
            },
            {
              icon: <Star2Icon className="h-6 w-6" />,
              label: 'ATP from Watch Game',
              data: userPoints.game,
            },
          ]}
          earlyBonus={points?.UserPoints?.earlyBonus}
        />
        <Banner />
        <Referral />
      </div>
    </Skeleton.Provider>
  );
}
